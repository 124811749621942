import { BannerSlugTypeEnum, ImageTypeBannerResponseDto, TextTypeBannerResponseDto } from '@/apis/rest/generated/types'
import {
  ProductDetailTextBanner,
  ProductDetailImageBanner,
  HomeTextBanner,
  CompleteOrderPageImageBanner,
} from '@/types/banner.type'

type TextBanners = TextTypeBannerResponseDto[]
type ImageBanners = ImageTypeBannerResponseDto[]

const makeProductDetailTextBanners = (
  textBanners: TextBanners,
  productId: number,
  productName: string
): ProductDetailTextBanner[] =>
  textBanners.map((d, idx) => ({
    id: d.id,
    linkUrl: d.link,
    bannerText: d.title,
    trackerProperties: {
      bannerIndex: idx,
      bannerText: d.rawTitle,
      bannerItemId: d.id,
      productId,
      productName,
      ...(d.slug.type === BannerSlugTypeEnum.EVENT ? { eventsaleSlug: d.slug.value } : { otherSlug: d.slug.value }),
    },
  }))

const makeProductDetailImageBanners = (
  imageBanners: ImageBanners,
  productId: number,
  productName: string
): ProductDetailImageBanner[] =>
  imageBanners.map((d, idx) => ({
    id: d.id,
    linkUrl: d.link,
    imageUrl: d.imageUrl,
    trackerProperties: {
      bannerIndex: idx,
      bannerItemId: d.id,
      bannerText: d.imageUrl,
      productId,
      productName,
      ...(d.slug.type === BannerSlugTypeEnum.EVENT ? { eventsaleSlug: d.slug.value } : { otherSlug: d.slug.value }),
    },
  }))

const makeHomeTextBanners = (textBanners: TextBanners): HomeTextBanner[] =>
  textBanners.map((d, idx) => ({
    id: d.id,
    linkUrl: d.link,
    bannerText: d.title,
    trackerProperties: {
      bannerIndex: idx,
      bannerText: d.rawTitle,
      textbannerType: d.tags.join(','),
      mileageExpectedSum: d.expectedReward ?? 0,
      ...(d.slug.type === BannerSlugTypeEnum.EVENT ? { eventsaleSlug: d.slug.value } : { otherSlug: d.slug.value }),
    },
  }))

const makeCompleteOrderPageImageBanners = (imageBanners: ImageBanners): CompleteOrderPageImageBanner[] =>
  imageBanners.map((d, index) => ({
    id: d.id,
    linkUrl: d.link,
    imageUrl: d.imageUrl,
    trackerProperties: {
      bannerIndex: index,
      bannerItemId: d.id,
      bannerItemName: d.title,
      bannerText: d.imageUrl,
      ...(d.slug.type === BannerSlugTypeEnum.EVENT ? { eventsaleSlug: d.slug.value } : { otherSlug: d.slug.value }),
    },
  }))

export {
  makeProductDetailTextBanners,
  makeProductDetailImageBanners,
  makeHomeTextBanners,
  makeCompleteOrderPageImageBanners,
}
